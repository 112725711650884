import { Link } from "react-router-dom";
import { tokenDecode } from "../../../utils/helper";
import { useState } from "react";
// import logoimg from "../../../../src/icons/logoimg.png";
import logoimg from "../../../../src/icons/xpressbazarlogo.jpg"
import styles from "./Side_bar.module.css";
import { useEffect } from "react";
import { config } from "../../../utils/config";
import axios from "axios";
import { baseUrlDefiner } from "../../../utils/config";
import Cookies from 'js-cookie';
import jwt_decode from "jwt-decode";
import dashboardicon from "../../../icons/dashboardicon.png";
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import PaidIcon from '@mui/icons-material/Paid';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import ListAltIcon from '@mui/icons-material/ListAlt';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import GroupIcon from '@mui/icons-material/Group';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import Navbar from "../navbar";
import ideaMasterLogo from "../../../icons/ideamaster.png"
import { useHistory } from "react-router-dom";
const { apiEndpoint } = config;
const Sidebar = () => {
  const [arrows, setArrows] = useState({});
  const decoded = tokenDecode();
  const history = useHistory()
  const { data } = decoded;
  const { permission: { category, order, product, role, seller, tax, user, location, product_master_catalog, igm, logistic, transaction }, } = data;
  // console.log("permissions------->", data.permission)

  if (decoded) {
    // if (isAdmin === "true") {
    //     return children;
    // }
  } else {
    console.log("no");
  }
  // console.log("decoded?.data?.seller_data?.is_multi_shop ", decoded?.data?.seller_data?.is_multi_shop)
  const [numberOfNAP, setnumberOfNAP] = useState([]);

  const [sidebarShow, setsidebarShow] = useState(checkstatus());

  function checkstatus() {
    const token = sessionStorage.getItem("us_st_d");
    const tokenDecode = jwt_decode(token);
    // console.log("tokendecode from siebar---->", tokenDecode)

    return tokenDecode.data.user.approve_status;
  }
  // console.log("seller approve status from sidebar---->", sidebarShow)
  useEffect(() => {
    async function getNAP() {
      try {
        const { data } = await axios({
          url: `${apiEndpoint}/listNonApproveProductSeller`,
          method: "POST",
          headers: {
            "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
          },
          data: {
            seller_id: decoded?.data?.user?.seller_id,
          },
        });

        if (data.status === 200) {
          setnumberOfNAP(data.data);
          // console.log(data)
        }
      } catch (error) { }
    }
    // getNAP();

    function handleResize() {
      const location = window.location.href.split("/")
      // console.log(location[location.length - 1])
      if (window.innerWidth > 766) {
        if (location[location.length - 1] == "sidebar") {
          history.push("/dashboard")
        }
      }
    }

    window.addEventListener('resize', handleResize);

  }, []);




  let fullUrl = window.location.host;
  // console.log(numberOfNAP)

  // console.log("form sidebar>>>>", decoded.data.user.seller_id)
  let current_sellerid = decoded.data.user.seller_id;

  const handleClick = (id) => {
    setArrows((prevArrows) => {
      const newArrows = Object.fromEntries(
        Object.entries(prevArrows).map(([key, value]) => [key, false])
      );
      newArrows[id] = !prevArrows[id];
      return newArrows;
    });
  };

  return (
    <>
      <div className={styles.sidebar_navbar}>
        <Navbar></Navbar>
      </div>
      {sidebarShow == 1 ? (
        <ul
          className={`navbar-nav sidebar-dark accordion ${styles.side_bar}`}
          id="accordionSidebar"
        >
          <Link to="/dashboard">
            <a
              href="replace"
              className="sidebar-brand d-flex align-items-center justify-content-center"
            >
              <div className="sidebar-brand-icon rotate-n-15"></div>
              <div className="sidebar-brand-text ">
                {" "}
                {(fullUrl === "multipleselleradmin.thesellerapp.com") && <img
                  src={ideaMasterLogo}
                  style={{ height: "100px", width: "100px", background: "white", borderRadius: "50%", marginBottom: "10px" }}
                  alt="logo here"
                  className={styles.sideBarLogo}
                />}
                {(fullUrl === "preprodadmin.xpressbaazaar.com" || fullUrl === "localhost:3001" || fullUrl === "localhost:3000" || fullUrl === "localhost:3002") && <img
                  src={logoimg}
                  style={{ height: "85px", padding: "10px" }}
                  alt="logo here"
                  className={styles.sideBarLogo}
                />}
              </div>
            </a>
          </Link>
          <hr className={`sidebar-divider my-0 ${styles.sideBarLogo}`} />

          <li className="nav-item active">
            <Link to="/dashboard">
              <a
                href="replace"
                className="nav-link d-flex align-items-center justify-content-start"
                style={{ gap: "15px" }}
              >
                {/* <i className="fas fa-users-cog" ></i> */}
                {/* <img src={dashboardicon} alt="" /> */}
                <DashboardCustomizeIcon className={styles.icon_color}></DashboardCustomizeIcon>
                <span>Dashboard</span>
              </a>
            </Link>
          </li>



          {product?.read ? <><hr className="sidebar-divider" />
            <div className="sidebar-heading">Product Management</div>
            <>
              <li className="nav-item"
                style={{
                  display: `${(decoded?.data?.user?.seller_id === 505 || decoded?.data?.user?.email == "tf1001@ondc.org" || decoded?.data?.user?.email == "tf1002@ondc.org") ? "none" : "block"
                    }`,
                }}
              >
                <Link to="#">
                  <a className="nav-link collapsed d-flex justify-content-between align-items-center myaddedclass"
                    href="replace"
                    data-toggle="collapse"
                    data-target="#mastercatalog"
                    aria-expanded="true"
                    aria-controls="collapseTwo"
                    id="master-catalog"
                    onClick={() => handleClick("master-catalog")}
                  >
                    <div className={styles.listItems}>
                      {/* <i className="fas fa-fw fa-tablet"></i> */}
                      <ListAltIcon className={styles.icon_color}></ListAltIcon>
                      <span>Catalog</span>
                    </div>

                    <i
                      className={
                        arrows["master-catalog"]
                          ? `fas fa-angle-down `
                          : `fas fa-angle-right `
                      }
                    ></i>
                  </a>
                </Link>
                <div id="mastercatalog"
                  className="collapse"
                  aria-labelledby="headingTwo"
                  data-parent="#accordionSidebar"
                >
                  <div className={`bg-white  collapse-inner rounded ${styles.tabsInnerDiv}`}
                  >
                    {(current_sellerid < 2 && current_sellerid !== 0 && product.read) ? (
                      <>
                        {" "}
                        {(fullUrl === "preprodadmin.xpressbaazaar.com") && <>
                          <Link to="/mastercatalog">
                            <a
                              href="replace"
                              className={`collapse-item  ${styles.collapseitemText}`}
                            >
                              Add Catalog Product
                            </a>
                          </Link>
                          <Link to="/masterproductlist">
                            <a href="replace"
                              className={`collapse-item  ${styles.collapseitemText}`}
                            >
                              Master Product List
                            </a>
                          </Link>
                        </>}
                        <Link to="/logrejection">
                          <a href="replace"
                            className={`collapse-item  ${styles.collapseitemText}`}
                          >
                            Catalog Rejection
                          </a>
                        </Link>
                      </>
                    ) : (
                      <>
                        {product.read ? <> <Link to="/addinstoreproduct">
                          <a
                            href="replace"
                            className={`collapse-item  ${styles.collapseitemText}`}
                          >
                            Add Product
                          </a>
                        </Link>
                          <Link to="/instoreproducts">
                            <a
                              href="replace"
                              className={`collapse-item  ${styles.collapseitemText}`}
                            >
                              Instore Products
                            </a>

                          </Link>
                        </> : null}
                        {product.write ? <Link to="/productcatalog">
                          {(fullUrl === "preprodadmin.xpressbaazaar.com") && <a
                            href="replace"
                            className={`collapse-item  ${styles.collapseitemText}`}
                          >
                            Master Catalog
                          </a>}
                        </Link> : null}
                      </>
                    )}
                  </div>
                </div>
              </li>
            </></> : null}

          <>
            <li
              className="nav-item"
              style={{
                display: `${(decoded?.data?.user?.seller_id === 505 || decoded?.data?.user?.email == "tf1001@ondc.org" || decoded?.data?.user?.email == "tf1002@ondc.org") ? "block" : "none"
                  }`,
              }}
            >
              <Link to="/allorderslist">
                <a
                  className="nav-link  d-flex justify-content-between myaddedclass"
                  href="replace"
                  id="order"
                  onClick={() => handleClick("issues")}
                >
                  <div className={styles.listItems}>
                    {/* <i className="fas fa-fw fa-cog"></i> */}
                    <ReceiptLongIcon></ReceiptLongIcon>
                    <span>Order </span>
                  </div>

                </a>
              </Link>
            </li>
          </>
          <>
            <li className="nav-item" style={{
              display: `${(decoded?.data?.user?.seller_id === 505 || decoded?.data?.user?.email == "tf1001@ondc.org" || decoded?.data?.user?.email == "tf1002@ondc.org") ? "block" : "none"}`,
            }}>
              <Link to="/rspdashboard">
                <a className="nav-link  d-flex justify-content-between myaddedclass" href="replace" id="order" onClick={() => handleClick("issues")}
                >
                  <div className={styles.listItems}>
                    <ReceiptLongIcon></ReceiptLongIcon>
                    <span>RSF </span>
                  </div>

                </a>
              </Link>
            </li>

            <li
              className="nav-item"
              style={{
                display: `${(decoded?.data?.user?.seller_id === 505 || decoded?.data?.user?.email == "tf1001@ondc.org" || decoded?.data?.user?.email == "tf1002@ondc.org") ? "block" : "none"
                  }`,
              }}
            >
              <Link to="/supportlist" >
                <a className="nav-link  d-flex justify-content-between myaddedclass" href="replace" id="order" onClick={() => handleClick("issues")}
                >
                  <div className={styles.listItems}>
                    <ReceiptLongIcon></ReceiptLongIcon>
                    <span> Customer Support </span>
                  </div>

                </a>
              </Link>
            </li>
          </>



          {category?.write || category?.read ? (
            <>
              <li className="nav-item">
                <Link to="#">
                  <a className="nav-link collapsed d-flex justify-content-between myaddedclass" href="replace" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo" id="products"
                    onClick={() => handleClick("products")}>
                    <div className={styles.listItems}>
                      <PendingActionsIcon className={styles.icon_color}></PendingActionsIcon>
                      {current_sellerid == 1 ? (
                        <span>Category, Brand & Products </span>
                      ) : null}
                    </div>
                    <i className={arrows["products"] ? `fas fa-angle-down` : `fas fa-angle-right`}                    ></i>
                  </a>
                </Link>

                <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar"                >
                  <div className={`bg-white collapse-inner rounded ${styles.tabsInnerDiv}`}>
                    {(category?.write || category?.read) ? (
                      <Link to="/newcategory">
                        <a href="replace" className={`collapse-item  ${styles.collapseitemText}`}                        >
                          Category
                        </a>
                      </Link>
                    ) : null}

                    {(category?.write || category?.read) && (
                      <Link to="/brand">
                        <a href="replace" className={`collapse-item  ${styles.collapseitemText}`}                        >
                          Brand
                        </a>
                      </Link>
                    )}
                    {(category?.write || category?.read) && (
                      <Link to="/producttype">
                        <a href="replace" className={`collapse-item  ${styles.collapseitemText}`}                        >
                          Product Type
                        </a>
                      </Link>
                    )}

                    {(current_sellerid < 2 && current_sellerid !== 0) && (
                      <Link to="/productlist">
                        <a href="replace" className={`collapse-item  ${styles.collapseitemText}`}   >
                          Products
                        </a>
                      </Link>
                    )}


                  </div>
                </div>
              </li>
            </>
          ) : null}

          {(order?.read || transaction?.read) ? (
            <>
              <hr className="sidebar-divider" />
              <div className="sidebar-heading" style={{
                display: `${(decoded?.data?.user?.seller_id === 505 || decoded?.data?.user?.email == "tf1001@ondc.org" || decoded?.data?.user?.email == "tf1002@ondc.org") ? "none" : "block"
                  }`,
              }} >Order Management</div>

              {order?.read ? <li className="nav-item">
                <Link to="#">
                  <a className="nav-link collapsed d-flex justify-content-between myaddedclass" href="replace" data-toggle="collapse" data-target="#collapseTwo_1" aria-expanded="true" aria-controls="collapseTwo" id="orders"
                    onClick={() => handleClick("orders")} >
                    <div className={styles.listItems}>
                      <ReceiptLongIcon className={styles.icon_color}></ReceiptLongIcon>
                      <span>Orders</span>
                    </div>

                    <i className={arrows["orders"] ? `fas fa-angle-down` : `fas fa-angle-right`}  ></i>
                  </a>
                </Link>
                <div id="collapseTwo_1" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">

                  <div className={`bg-white  collapse-inner rounded ${styles.tabsInnerDiv}`}                  >

                    {order?.read ? (
                      <>
                        {current_sellerid === 1 &&
                          <Link to="/orderlist">
                            <a href="replace" className={`collapse-item  ${styles.collapseitemText}`}
                            >
                              Order List
                            </a>
                          </Link>
                        }

                        {decoded?.data?.user?.seller_id > 1 || (decoded?.data?.user?.seller_id === 0) ?
                          <>
                            <Link to="/customerorder">
                              <a href="replace" className={`collapse-item  ${styles.collapseitemText}`}
                              >
                                Active Orders
                              </a>
                            </Link>
                            <Link to="/completeOrder">
                              <a href="replace" className={`collapse-item  ${styles.collapseitemText}`}
                              >
                                Complete Orders
                              </a>
                            </Link>
                            <Link to="/cancelOrderlist">
                              <a href="replace" className={`collapse-item  ${styles.collapseitemText}`}
                              >
                                Cancelled Orders
                              </a>
                            </Link>

                          </> :
                          null}
                        <Link to="/returnorders">
                          <a href="replace" className={`collapse-item  ${styles.collapseitemText}`}
                          >
                            Return Orders List
                          </a>
                        </Link>

                        {current_sellerid === 1 ? <> <>

                          <Link to="/allorderslist">
                            <a href="replace" id="order" className={`collapse-item  ${styles.collapseitemText}`} >
                              Order
                            </a>
                          </Link>

                        </>
                          <>
                            <Link to="/rspdashboard">
                              <a href="replace" id="order" className={`collapse-item  ${styles.collapseitemText}`} >
                                RSF
                              </a>
                            </Link>

                          </>  </> : null}
                      </>
                    ) : null}
                  </div>
                </div>
              </li> : null}
            </>
          ) : null}

          {(transaction?.read && decoded.data.user.parent_id !== 519) ? (
            <>
              <li className="nav-item">

                <Link to="#">
                  <a className="nav-link collapsed d-flex justify-content-between myaddedclass" href="replace" data-toggle="collapse" data-target="#collapseTwo1_1" aria-expanded="true" aria-controls="collapseTwo" id="transaction"
                    onClick={() => handleClick("transaction")}
                  >

                    <div className={styles.listItems}>
                      <PaidIcon className={styles.icon_color}></PaidIcon>
                      <span>Transaction</span>
                    </div>
                    <i className={arrows["transaction"] ? `fas fa-angle-down` : `fas fa-angle-right`}  ></i>
                  </a>
                </Link>

                <div
                  id="collapseTwo1_1"
                  className="collapse"
                  aria-labelledby="headingTwo"
                  data-parent="#accordionSidebar"
                >
                  <div
                    className={`bg-white collapse-inner rounded ${styles.tabsInnerDiv}`}
                  >
                    {transaction?.read ? (
                      <Link to="/transaction">
                        <a
                          href="replace"
                          className={`collapse-item  ${styles.collapseitemText}`}
                        >
                          Settled  Transaction
                        </a>
                      </Link>
                    ) : null}
                    {transaction?.read ? (
                      <Link to="/unsettledtransactions">
                        <a
                          href="replace"
                          className={`collapse-item  ${styles.collapseitemText}`}
                        >
                          Unsettled  Transaction
                        </a>
                      </Link>
                    ) : null}
                    {(fullUrl === "preprodadmin.xpressbaazaar.com") && <> {transaction?.read ? (
                      <Link to="/payments">
                        <a href="replace" className={`collapse-item  ${styles.collapseitemText}`}>
                          Payments
                        </a>
                      </Link>
                    ) : null}
                      {transaction?.read ? (
                        <Link to="/return">
                          <a href="replace" className={`collapse-item  ${styles.collapseitemText}`}>
                            Payments Return
                          </a>
                        </Link>
                      ) : null} </>}
                  </div>
                </div>
              </li>
            </>
          ) : null}

          {/*----------------------------------- IGM Start---------------------------------------------------------- */}
          {(igm?.read || igm?.write) ? <>
            <hr className="sidebar-divider" />
            <div className="sidebar-heading" >Grievance Management</div>
            <>
              <li className="nav-item">
                <Link to="#">
                  <a className="nav-link collapsed d-flex justify-content-between myaddedclass" href="replace" data-toggle="collapse" data-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree" id="issues"
                    onClick={() => handleClick("issues")}
                  >
                    <div className={styles.listItems}>
                      <ContactSupportIcon className={styles.icon_color}></ContactSupportIcon>
                      <span>IGM Portal </span>
                    </div>

                    <i className={arrows["issues"] ? `fas fa-angle-down` : `fas fa-angle-right`}></i>

                  </a>
                </Link>
                <div id="collapseThree" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar"                  >
                  <div className={`bg-white collapse-inner rounded ${styles.tabsInnerDiv}`}  >

                    <Link to="/issues"  >
                      <a href="replace" className={`collapse-item  ${styles.collapseitemText}`}  >
                        IGM
                      </a>
                    </Link>

                    {/*----------------- SHOWING CUSTOMER SUPPORT TO ADMIN ONLY  -----------------------*/}
                    {current_sellerid === 1 ? <Link to="/supportlist"  >
                      <a href="replace" className={`collapse-item  ${styles.collapseitemText}`} >
                        Customer Support
                      </a>
                    </Link> : null}
                  </div>
                </div>
              </li>
            </>
          </> : null}
          {/*--------------------------------- IGM END ------------------------------------------*/}
          {/*----------------------------------- Logistic Start---------------------------------------------------------- */}
          {(logistic?.read || logistic?.write) ? <>
            <hr className="sidebar-divider" />
            <div className="sidebar-heading" >Logistic Management</div>
            <>
              <li className="nav-item">
                <Link to="#">
                  <a className="nav-link collapsed d-flex justify-content-between myaddedclass" href="replace" data-toggle="collapse" data-target="#collapselbnp" aria-expanded="true" aria-controls="collapseThree" id="issues"
                    onClick={() => handleClick("logistic")}
                  >
                    <div className={styles.listItems}>
                      <LocalShippingIcon className={styles.icon_color}></LocalShippingIcon>
                      <span>Logistic Portal</span>
                    </div>

                    <i className={arrows["logistic"] ? `fas fa-angle-down` : `fas fa-angle-right`}></i>

                  </a>
                </Link>
                <div id="collapselbnp" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar"                  >
                  <div className={`bg-white collapse-inner rounded ${styles.tabsInnerDiv}`}  >

                    <Link to="/logistic">
                      <a href="replace" className={`collapse-item  ${styles.collapseitemText}`}>
                        Logistic
                      </a>
                    </Link>

                    {/*----------------- SHOWING CUSTOMER SUPPORT TO ADMIN ONLY  -----------------------*/}
                    {/* {current_sellerid === 1 ? <Link to="/supportlist"  >
                      <a href="replace" className={`collapse-item  ${styles.collapseitemText}`} >
                        Customer Support
                      </a>
                    </Link> : null} */}
                  </div>
                </div>
              </li>
            </>
          </> : null}
          {/*--------------------------------- Logistic END ------------------------------------------*/}



          {/* --------------------------------------SETTINGS------------------------------------ */}

          <section style={{
            display: `${(decoded?.data?.user?.seller_id === 505 || decoded?.data?.user?.email == "tf1001@ondc.org" || decoded?.data?.user?.email == "tf1002@ondc.org") ? "none" : "block"
              }`,
          }}>
            <hr className="sidebar-divider" />
            <div className="sidebar-heading" >Interface</div>

            <>
              <>
                {user?.read || user?.write || role?.write ? (
                  <>
                    <Link
                      to="#"
                      className="nav-link collapsed d-flex justify-content-between align-items-center myaddedclass"
                      data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseTwo" id="users"
                      onClick={() => handleClick("users")}
                    >
                      <div className={styles.listItems}>
                        <SupervisedUserCircleIcon className={styles.icon_color}></SupervisedUserCircleIcon>
                        <span>Users</span>
                      </div>
                      <i className={arrows["users"] ? `fas fa-angle-down ` : `fas fa-angle-right `}                      ></i>
                    </Link>
                    <div id="collapseOne" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar"
                    >
                      <div className={`bg-white collapse-inner rounded ${styles.tabsInnerDiv}`}                      >
                        {role?.write ? (
                          <Link to="/roles">
                            <a href="replace" className={`collapse-item text-capitalize  ${styles.collapseitemText}`}
                            >
                              Roles
                            </a>
                          </Link>
                        ) : null}
                        {user?.write ? (
                          <Link to="/adduser">
                            <a
                              href="replace"
                              className={`collapse-item  text-capitalize ${styles.collapseitemText}`}
                            >
                              Add user
                            </a>
                          </Link>
                        ) : null}
                        {user?.read ? (
                          <Link to="/userlist">
                            <a
                              href="replace"
                              className={`collapse-item   text-capitalize ${styles.collapseitemText}`}
                            >
                              User List
                            </a>
                          </Link>
                        ) : null}
                      </div>
                    </div>
                  </>
                ) : null}
              </>

              <li className="nav-item ">
                {seller?.read || seller?.write || tax?.write || decoded?.data?.seller_data?.is_multi_shop === true ? (
                  <>
                    <Link to="#" className="nav-link collapsed d-flex justify-content-between align-items-center myaddedclass" data-toggle="collapse" data-target="#seller11" aria-expanded="true"
                      aria-controls="collapseTwo"
                      onClick={() => handleClick("sellers")}
                    >
                      <div className={styles.listItems}>
                        <GroupIcon className={styles.icon_color}></GroupIcon>
                        <span>Sellers</span>
                      </div>

                      <i className={arrows["sellers"] ? `fas fa-angle-down ` : `fas fa-angle-right `}  ></i>
                    </Link>


                    <div id="seller11" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar"  >

                      <div className={`bg-white  collapse-inner rounded ${styles.tabsInnerDiv}`}    >

                        {(seller?.write || decoded?.data?.seller_data?.is_multi_shop === true) ? (
                          <Link to="/addseller">
                            <a href="replace" className={`collapse-item  ${styles.collapseitemText}`}       >
                              Add seller
                            </a>
                          </Link>
                        ) : null}

                        {(seller?.read || decoded?.data?.seller_data?.is_multi_shop === true) ? (
                          <Link to="/sellerlist">
                            <a href="replace" className={`collapse-item  ${styles.collapseitemText}`}     >
                              Seller List
                            </a>
                          </Link>
                        ) : null}

                        {tax.write ? (
                          <Link to="/tax">
                            <a href="replace" className={`collapse-item  ${styles.collapseitemText}`}    >
                              Tax
                            </a>
                          </Link>
                        ) : null}
                      </div>
                    </div>
                  </>
                ) : null}



              </li>


              {(fullUrl === "preprodadmin.xpressbaazaar.com") && <li className="nav-item"              >
                <Link to="#">
                  <a
                    className="nav-link collapsed d-flex justify-content-between myaddedclass"
                    href="replace"
                    data-toggle="collapse"
                    data-target="#collapseSettings"
                    aria-expanded="true"
                    aria-controls="collapseSettings"
                    id="settings"
                    onClick={() => handleClick("settings")}
                  >
                    <div className={styles.listItems}>
                      {/* <i className="fas fa-fw fa-cog"></i> */}
                      <SettingsApplicationsIcon className={styles.icon_color}></SettingsApplicationsIcon>
                      {/* <span>IGM Portal </span> */}
                      <span>Settings </span>
                    </div>
                    <i
                      className={
                        arrows["settings"]
                          ? `fas fa-angle-down`
                          : `fas fa-angle-right`
                      }
                    ></i>
                  </a>
                </Link>
                <div
                  id="collapseSettings"
                  className="collapse"
                  aria-labelledby="headingTwo"
                  data-parent="#accordionSidebar"
                >
                  <div className={`bg-white collapse-inner rounded ${styles.tabsInnerDiv}`}>
                    <Link to="/settings">
                      <a href="replace"
                        className={`collapse-item  ${styles.collapseitemText}`}
                      >
                        Settings
                      </a>
                    </Link>

                  </div>
                </div>
              </li>}
            </>
          </section>


          {/* ----------------------------------------FINANCE----------------------------------------------------- */}


          {/* <section style={{
            display: `${(decoded?.data?.user?.seller_id === 505 || decoded?.data?.user?.email == "tf1001@ondc.org" || decoded?.data?.user?.email == "tf1002@ondc.org") ? "none" : "block"
              }`,
          }}>
            <hr className="sidebar-divider" />
            <div className="sidebar-heading" >Finance</div>

            <>
              <>
                {seller?.read || seller?.write || seller?.write ? (
                  <>
                    <Link
                      to="#"
                      className="nav-link collapsed d-flex justify-content-between align-items-center myaddedclass"
                      data-toggle="collapse" data-target="#collapseFinance" aria-expanded="true" aria-controls="collapseTwo" id="finance"
                      onClick={() => handleClick("finance")}
                    >
                      <div className={styles.listItems}>
                        <SupervisedUserCircleIcon className={styles.icon_color}></SupervisedUserCircleIcon>
                        <span>Finance</span>
                      </div>
                      <i className={arrows["finance"] ? `fas fa-angle-down ` : `fas fa-angle-right `}></i>
                    </Link>
                    <div id="collapseFinance" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar"
                    >
                      <div className={`bg-white collapse-inner rounded ${styles.tabsInnerDiv}`}>
                        {role?.write ? (
                          <Link to="/finance/itemlist">
                            <a href="replace" className={`collapse-item text-capitalize  ${styles.collapseitemText}`}
                            >
                              Items
                            </a>
                          </Link>
                        ) : null}
                        {role?.write ? (
                          <Link to="/finance/addseller">
                            <a href="replace" className={`collapse-item text-capitalize  ${styles.collapseitemText}`}
                            >
                              Sellers
                            </a>
                          </Link>
                        ) : null}
                        {role?.write ? (
                          <Link to="/finance/orderlist">
                            <a href="replace" className={`collapse-item text-capitalize  ${styles.collapseitemText}`}
                            >
                              Orders In Process
                            </a>
                          </Link>
                        ) : null}

                      </div>
                    </div>
                  </>
                ) : null}
              </>


            </>
          </section> */}



          <section
            style={{
              display: `${decoded?.data?.user?.seller_id === 505 ||
                  decoded?.data?.user?.email == "tf1001@ondc.org" ||
                  decoded?.data?.user?.email == "tf1002@ondc.org"
                  ? "none"
                  : "block"
                }`,
            }}
          >
            <hr className="sidebar-divider" />
            <div className="sidebar-heading">Finance</div>

            <>
              <>
                {seller?.read || seller?.write ? (
                  <>
                    <Link
                      to="#"
                      className="nav-link collapsed d-flex justify-content-between align-items-center myaddedclass"
                      data-toggle="collapse"
                      data-target="#collapseFinance"
                      aria-expanded="true"
                      aria-controls="collapseTwo"
                      id="finance"
                      onClick={() => handleClick("finance")}
                    >
                      <div className={styles.listItems}>
                        <SupervisedUserCircleIcon
                          className={styles.icon_color}
                        ></SupervisedUserCircleIcon>
                        <span>Finance</span>
                      </div>
                      <i
                        className={
                          arrows["finance"]
                            ? `fas fa-angle-down `
                            : `fas fa-angle-right `
                        }
                      ></i>
                    </Link>

                    {/* Finance Collapse */}
                    <div
                      id="collapseFinance"
                      className="collapse"
                      aria-labelledby="headingTwo"
                      data-parent="#accordionSidebar"
                    >
                      <div
                        className={`bg-white collapse-inner rounded ${styles.tabsInnerDiv}`}
                      >
                        {/* Investment Submenu */}
                        <Link
                          to="#"
                          className="nav-link collapsed d-flex justify-content-between align-items-center"
                          data-toggle="collapse"
                          data-target="#collapseInvestment"
                          aria-expanded="true"
                          aria-controls="collapseInvestment"
                        >
                          <span>Investment</span>
                          <i
                            className={
                              arrows["investment"]
                                ? `fas fa-angle-down `
                                : `fas fa-angle-right `
                            }
                          ></i>
                        </Link>
                        <div
                          id="collapseInvestment"
                          className="collapse"
                          aria-labelledby="headingInvestment"
                        >
                          <div className={`bg-white collapse-inner rounded`}>
                            {role?.write ? (
                              <Link to="/finance/investment/itemlist">
                                <a
                                  href="replace"
                                  className={`collapse-item text-capitalize ${styles.collapseitemText}`}
                                >
                                  Investment Items
                                </a>
                              </Link>
                            ) : null}
                          </div>
                        </div>

                        {/* Personal Loan Submenu */}
                        <Link
                          to="#"
                          className="nav-link collapsed d-flex justify-content-between align-items-center"
                          data-toggle="collapse"
                          data-target="#collapsePersonalLoan"
                          aria-expanded="true"
                          aria-controls="collapsePersonalLoan"
                        >
                          <span>Personal Loan</span>
                          <i
                            className={
                              arrows["personalLoan"]
                                ? `fas fa-angle-down `
                                : `fas fa-angle-right `
                            }
                          ></i>
                        </Link>
                        <div
                          id="collapsePersonalLoan"
                          className="collapse"
                          aria-labelledby="headingPersonalLoan"
                        >
                          <div className={`bg-white collapse-inner rounded`}>
                            {role?.write ? (
                              <Link to="/finance/itemlist">
                                <a
                                  href="replace"
                                  className={`collapse-item text-capitalize ${styles.collapseitemText}`}
                                >
                                  Items
                                </a>
                              </Link>
                            ) : null}
                            <br></br>
                            {role?.write ? (
                              <Link to="/finance/addseller">
                                <a href="replace" className={`collapse-item text-capitalize  ${styles.collapseitemText}`}
                                >
                                  Sellers
                                </a>
                              </Link>
                            ) : null}
                            <br></br>
                            {role?.write ? (
                              <Link to="/finance/orderlist">
                                <a
                                  href="replace"
                                  className={`collapse-item text-capitalize ${styles.collapseitemText}`}
                                >
                                  Orders In Process
                                </a>
                              </Link>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}
              </>
            </>
          </section>

          {/* {location?.read ? (
          <>
            <li className="nav-item">
              <Link to="#">
                <a
                  className="nav-link collapsed"
                  href="replace"
                  data-toggle="collapse"
                  data-target="#location"
                  aria-expanded="true"
                  aria-controls="collapseTwo"
                >
                  <i className="fas fa-fw fa-cog"></i>
                  <span>Location</span>
                </a>
              </Link>
              <div
                id="location"
                className="collapse"
                aria-labelledby="headingTwo"
                data-parent="#accordionSidebar"
              >
                <div className="bg-white py-2 collapse-inner rounded">
                  {location?.read ? (
                    <Link to="/location">
                      <a href="replace" className={`collapse-item  ${styles.collapseitemText}`}>
                        Add location
                      </a>
                    </Link>
                  ) : null}
                </div>
              </div>
            </li>
          </>
        ) : null} */}
          {/* <hr className="sidebar-divider d-none d-md-block" /> */}

          {/* MasterCatalog another field:- */}

          {/* <>

          <li className="nav-item">
            <Link to="#" >
              <a className="nav-link collapsed" href="replace" data-toggle="collapse" data-target="#mastercatalog"
                aria-expanded="true" aria-controls="collapseTwo">
                <i className="fas fa-fw fa-cog"></i>
                <span>Master Catalog</span>
              </a>
            </Link>
            <div id="mastercatalog" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
              <div className="bg-white py-2 collapse-inner rounded">

                {(current_sellerid < 2) ? <> <Link to="/mastercatalog" >
                  <a href="replace" className={`collapse-item  ${styles.collapseitemText}`}>Add catalog Product</a>
                </Link>
                  <Link to="/masterproductlist" >
                    <a href="replace" className={`collapse-item  ${styles.collapseitemText}`}>Master Product List</a>
                  </Link></> : <> <Link to="/masterproductlist" >
                    <a href="replace" className={`collapse-item  ${styles.collapseitemText}`}>Master Product List</a>
                  </Link></>}

              </div>
            </div>
          </li>

        </> */}
        </ul>
      ) : (
        <h5 className="text-center">Approval Status is pending</h5>
      )}
    </>
  );
};

export default Sidebar;
