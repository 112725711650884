import { useEffect, useState } from 'react'
import ThemeMaker from '../../components/thememaker'
import axios from 'axios';
import { toast } from 'react-toastify';
import { Paginated } from '../../components/Paginated';

import { NodebaseUrlDefiner } from '../../utils/config';
export default function ActiveState({transaction_id,table}) {
    const [financeUserDetails, setFinanceUserDetails] = useState([])
    useEffect(() => {
      getfinanceUserDetails();
    }, [])
  
    if (table=='searchformdata') {
        
    }
   
    async function getfinanceUserDetails() {
      try {
        let apiEndpoint = NodebaseUrlDefiner();
        const { data } = await axios({
          url: `${apiEndpoint}/adminCredit/getUserDetails`,
          method: "GET"
        })
        setFinanceUserDetails(data ? data.data : []);
      } catch (err) {
        console.log(err)
        toast.error("Error fetching sellers!")
      }
    }
    
    console.log("financeUserDetails288888888", table);
    const COLUMNS = [
      {
        Header: "Sn No.",
        accessor: (row, count) => {
          return <>{count + 1}</>
        },
        disableFilters: true,
        sticky: "left"
      },
      {
        Header: "First Name",
        accessor: (data) => (data?.firstName ? data?.firstName : ""),
        sticky: "left"
      },
      {
        Header: "Last Name",
        accessor: (data) => (data?.lastName ? data?.lastName : ""),
        sticky: "left",
      },
      {
        Header: "Email",
        accessor: (data) => (data?.email ? data?.email : ""),
        sticky: "left",
      },
      {
        Header: "Official Email",
        accessor: (data) => (data?.officialEmail ? data?.officialEmail : ""),
        sticky: "left",
      },
      {
        Header: "DOB",
        accessor: (data) => (data?.dob ? data?.dob : ""),
        sticky: "left",
      },
      {
        Header: "Gender",
        accessor: (data) => (data.gender ? data.gender : ""),
        sticky: "left",
      },
      {
        Header: "PAN",
        accessor: (data) => (data.pan ? data.pan : ""),
        sticky: "left",
      },
      {
        Header: "Contact Number",
        accessor: (data) => (data.contactNumber ? data.contactNumber : ""),
        sticky: "left",
      },
      {
        Header: "Employment Type",
        accessor: (data) => (data.employmentType ? data.employmentType : ""),
        sticky: "left",
      },
      {
        Header: "Income",
        accessor: (data) => (data.income ? data.income : ""),
        sticky: "left",
      },
      {
        Header: "Company",
        accessor: (data) => (data.companyName ? data.companyName : ""),
        sticky: "left",
      },
      {
        Header: "Udyam Number",
        accessor: (item) => (item?.udyamNumber ? item?.udyamNumber : ""),
        sticky: "left",
      },
      {
        Header: "Address1",
        accessor: (item) => (item?.addressL1 ? item?.addressL1 : ""),
        sticky: "left",
      },
      {
        Header: "Address2",
        accessor: (item) => (item?.addressL2 ? item?.addressL2 : ""),
        sticky: "left",
      },
      {
        Header: "City",
        accessor: (item) => (item?.city ? item?.city : ""),
        sticky: "left",
      },
      {
        Header: "State",
        accessor: (item) => (item?.state ? item?.state : ""),
        sticky: "left",
      },
      {
        Header: "Pincode",
        accessor: (item) => (item?.pincode ? item?.pincode : ""),
        sticky: "left",
      },
      {
        Header: "AA_id",
        accessor: (item) => (item?.aa_id ? item?.aa_id : ""),
        sticky: "left",
      },
      {
        Header: "End Use",
        accessor: (item) => (item?.endUse ? item?.pincode : ""),
        sticky: "left",
      },
      {
        Header: "Bureau Consent",
        accessor: (item) => (item?.bureauConsent ? item?.bureauConsent : ""),
        sticky: "left",
      },
      {
        Header: "FormId",
        accessor: (item) => (item?.formId ? item?.formId : ""),
        sticky: "left",
      },
      {
        Header: "Status",
        accessor: (item) => (item?.staus ? item?.staus : ""),
        sticky: "left",
      },
      {
        Header: "Submission Id",
        accessor: (item) => (item?.submission_id ? item?.submission_id : ""),
        sticky: "left",
      },
      {
        Header: "Created At",
        accessor: (item) => (item?.created_at ? item?.created_at : ""),
        sticky: "left",
      },
      {
        Header: "Updated At",
        accessor: (item) => (item?.updated_at ? item?.updated_at : ""),
        sticky: "left",
      },
    ];
    return (
        <>
            <Paginated data={financeUserDetails} columns={COLUMNS} />
        </>
  )
}
