
import { useEffect, useState } from 'react'
import ThemeMaker from '../../components/thememaker'
import axios from 'axios';
import { toast } from 'react-toastify';
import { NodebaseUrlDefiner } from '../../utils/config';
import { Paginated } from '../../components/Paginated';
import { Link } from 'react-router-dom';

export default function RequestedLoanAmt({transaction_id}) {

  const [financeUserDetails, setFinanceUserDetails] = useState([])

  useEffect(() => {
    getfinanceUserDetails();
  }, [])

  async function getfinanceUserDetails() {
    try {
      let apiEndpoint = NodebaseUrlDefiner();
      const { data } = await axios({
        url: `${apiEndpoint}/adminCredit/requestedLoanAmtById/${transaction_id}`,
        method: "GET"
      })
      setFinanceUserDetails(data ? data.data : []);
    } catch (err) {
      console.log(err)
      toast.error("Error fetching sellers!")
    }
  }

  console.log("financeUserDetails32222",JSON.stringify(financeUserDetails));
  const COLUMNS = [
    {
      Header: "Sn No.",
      accessor: (row, count) => {
        return <>{count + 1}</>
      },
      disableFilters: true,
      sticky: "left"
    },
    {
      Header: "Loan Term",
      accessor: (data) => (data?.requested_loan_term ? data?.requested_loan_term : ""),
      sticky: "left"
    },
    {
      Header: "Amount",
      accessor: (data) => (data?.requested_loan_amount ? data?.requested_loan_amount : ""),
      sticky: "left",
    },
    {
      Header: "Form Id",
      accessor: (data) => (data?.form_id ? data?.form_id : ""),
      sticky: "left",
    },
    {
      Header: "Status",
      accessor: (data) => (data?.status ? data?.status : ""),
      sticky: "left",
    },
    {
      Header: "Submission Id",
      accessor: (data) => (data?.submission_id ? data?.submission_id : ""),
      sticky: "left",
    },
    {
      Header: "Transaction Id",
      accessor: (data) => (data?.transaction_id ? data?.transaction_id : ""),
      sticky: "left",
    },
    {
      Header: "Created At",
      accessor: (item) => (item?.created_at ? item?.created_at : ""),
      sticky: "left",
    },
    {
      Header: "Updated At",
      accessor: (item) => (item?.updated_at ? item?.updated_at : ""),
      sticky: "left",
    },
  ];
  return (
    <div className="card-body p-0 p-1" style={{ minHeight: "70vh", overflowX: 'auto' }}>
      <Paginated data={financeUserDetails} columns={COLUMNS} />
    </div>
  )
}
