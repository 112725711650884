// import { useEffect, useState } from 'react'
// import ThemeMaker from '../../components/thememaker'
// import axios from 'axios';
// import { toast } from 'react-toastify';
// import { NodebaseUrlDefiner } from '../../utils/config';

import { useEffect, useState } from 'react'
import ThemeMaker from '../../components/thememaker'
import axios from 'axios';
import { toast } from 'react-toastify';
import { NodebaseUrlDefiner } from '../../utils/config';
// import { Paginated } from '../../components/Paginated';
// import { Link } from 'react-router-dom';
import { useParams } from "react-router-dom";

import OrderTracking from './OrderTracking.jsx';
export default function OrderDetails() {
    let params = useParams();
    const { id: transaction_id } = params;
    const [financeOrderList, setFinanceOrderList] = useState([]);
    const [currentStep, setCurrentStep] = useState(1); // Define state to track current step

    useEffect(() => {
        getFinanceOrderList();
    }, []); // Fetch data when the component mounts
    
    useEffect(() => {
        console.log("Updated financeOrderList:", financeOrderList);
    }, [financeOrderList]); // Logs the updated state after setFinanceOrderList
    
    async function getFinanceOrderList() {
        try {
            let apiEndpoint = NodebaseUrlDefiner();
            const response = await axios({
                url: `${apiEndpoint}/adminCredit/UserDetailsById/${transaction_id}`,
                method: "GET"
            });
            // Check if the response has the data object and status
            const data = response.data;
    
            // Log the raw response to see the exact structure
            console.log("Dataa2333", JSON.stringify(data));  // Logs the raw data response
    
            // Handle data properly based on structure
            if (response.status === 200 && data?.data) {
                setFinanceOrderList(data.data);
                console.log("Setting financeOrderList with:", data.data);
            } else {
                setFinanceOrderList([]);
                console.log("No valid data found");
            }
        } catch (err) {
            console.log("Error:", err);
            toast.error("Error fetching sellers!");
        }
    }
    // This will log the current state, but remember this happens before the state is updated
    // console.log("Current financeOrderList544444444:", financeOrderList); 
    const COLUMNS = [
        {
            Header: "Sn No.",
            accessor: (row, count) => <>{count + 1}</>,
            disableFilters: true,
            sticky: "left"
        },
        {
            Header: "Provider Name",
            accessor: item => item?.provider_name || "",
            sticky: "left"
        },
        {
            Header: "Provider Id",
            accessor: item => item?.provider_id || "",
            sticky: "left",
        },
        {
            Header: "Order Id",
            accessor: item => item?.order_id || "",
            sticky: "left",
        },
        {
            Header: "Order Status",
            accessor: item => item?.order_status || "",
            sticky: "left",
        },
    ];
     
    return (
        <ThemeMaker>
            <div className="row w-100 m-0 mt-1">
                <div className="col-md-12 ">
                    <div className="card shadow mb-4">
                        <div className="card-header py-3">
                            <h6 className="m-0 font-weight-bold text-primary text-left">Transaction Id: {transaction_id}</h6>
                        </div>
                        <div className="card-body p-0 p-1" style={{ minHeight: "70vh",overflowX: 'auto' }}>
                        <OrderTracking currentStep={currentStep} setCurrentStep={setCurrentStep} transaction_id={transaction_id} />
                        </div>
                        
                        {/* Your existing order list */}
                        <div className="order-list">
                            {financeOrderList.map((order, index) => (
                                <div key={index}>
                                    <p>Order ID:{transaction_id} {order.order_id}</p>
                                    {/* Set the current step dynamically based on order status */}
                                    <button onClick={() => setCurrentStep(order.order_status_step)}>Track Order</button>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </ThemeMaker>
    );
}