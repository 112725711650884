
import { useEffect, useState } from 'react'
import ThemeMaker from '../../components/thememaker'
import axios from 'axios';
import { toast } from 'react-toastify';
import { NodebaseUrlDefiner } from '../../utils/config';
import { Paginated } from '../../components/Paginated';
import { Link } from 'react-router-dom';

export default function KycFormData({transaction_id}) {

  const [financeUserDetails, setFinanceUserDetails] = useState([])
  const [status, setstatus] = useState("");
  useEffect(() => {
    getfinanceUserDetails();
  }, [])

  async function getfinanceUserDetails() {
    try {
      let apiEndpoint = NodebaseUrlDefiner();
      const { data } = await axios({
        url: `${apiEndpoint}/adminCredit/getKycDataById/${transaction_id}`,
        method: "GET"
      })
      setFinanceUserDetails(data ? data.data : []);
    } catch (err) {
      console.log(err)
      toast.error("Error fetching sellers!")
    }
  }

  console.log("financeUserDetails3222",financeUserDetails);
  const COLUMNS = [
    {
      Header: "Sn No.",
      accessor: (row, count) => {
        return <>{count + 1}</>
      },
      disableFilters: true,
      sticky: "left"
    },
    {
      Header: "Pan No",
      accessor: (data) => (data?.pan_no ? data?.pan_no : ""),
      sticky: "left"
    },
    {
      Header: "Aadhar No",
      accessor: (data) => (data?.aadhar_no ? data?.aadhar_no : ""),
      sticky: "left",
    },
    {
      Header: "Form Id",
      accessor: (data) => (data?.form_id ? data?.form_id : ""),
      sticky: "left",
    },
    {
      Header: "Submission Id",
      accessor: (data) => (data?.submission_id ? data?.submission_id : ""),
      sticky: "left",
    },
    {
      Header: "Status",
      accessor: (data) => (data?.status ? data?.status : ""),
      sticky: "left",
    },
    {
      Header: "Created At",
      accessor: (item) => (item?.created_at ? item?.created_at : ""),
      sticky: "left",
    },
    {
      Header: "Updated At",
      accessor: (item) => (item?.updated_at ? item?.updated_at : ""),
      sticky: "left",
    },
    {
      Header: "Action",
      accessor: (item) => (item?.updated_at ? item?.updated_at : ""),
      sticky: "left",
    },
  ];
  const changeReturnStatus = (item, selectedStatus) => {
    console.log(
      "changeReturnStatus item: 763 ",
      item,
      "changeReturnStatus: selectedStatus 765",
      selectedStatus
    );
    item.status = selectedStatus;
    setstatus(selectedStatus);
  };
  return (
    <div className="card-body p-0 p-1" style={{ minHeight: "70vh", overflowX: 'auto' }}>
      {/* <Paginated data={financeUserDetails} columns={COLUMNS} /> */}
      <div>
                <table className="table-responsive table">
                  <thead>
                    <tr role="row" className="text-capitalize text-nowrap overflow-hidden">
                      <th>Sn No.</th>
                      <th>Pan No</th>
                      <th>Aadhar No</th>
                      <th>Form Id</th>
                      <th>Submission Id</th>
                      <th>Status</th>
                      <th>Created At</th>
                      <th>Updated At</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {financeUserDetails &&
                      financeUserDetails.map((items, index) => {
                        return (
                          <tr>
                            <td>{index + 1}</td>
                            <td>{items?.pan_no}</td>
                            <td>{items?.aadhar_no}</td>
                            <td>{items.form_id}</td>
                            <td>{items?.submission_id}</td>
                            <td>{items?.status}</td>
                            <td>{items?.created_at}</td>
                            <td>{items?.updated_at}</td>
                            <td>
                              <select
                                className="btn btn-outline-dark"
                                // value={items.fulfillment_state}
                                onChange={(e) =>
                                  changeReturnStatus(items, e.target.value)
                                }
                              >
                                <option value="Return_Initiated">
                                  Return_Initiated
                                </option>
                                <option value="Return_Approved">
                                  Return_Approved
                                </option>
                                <option value="Return_Rejected">
                                  Return_Rejected
                                </option>
                                <option value="Return_Picked">
                                  Return_Picked
                                </option>
                                <option value="Return_Delivered">
                                  Return_Delivered
                                </option>
                                <option value="Liquidated">Liquidated</option>
                                <option value="Cancelled">Cancelled</option>
                              </select>
                              <p className="d-none">{`You selected : ${status}`}</p>
                              <button
                                className="btn btn-outline-primary"
                                // onClick={() =>
                                //   orderStatusUpdate(
                                //     items?.order_id,
                                //     items?.product_id
                                //   )
                                // }
                              >
                                Update
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
    </div>
  )
}
