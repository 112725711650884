import { useEffect, useState } from 'react'
import ThemeMaker from '../../components/thememaker'
import axios from 'axios';
import { toast } from 'react-toastify';
import { NodebaseUrlDefiner } from '../../utils/config';
import { Paginated } from '../../components/Paginated';
import { Link } from 'react-router-dom';


export default function FinanceOrderList() {

    const [userList, setuserList] = useState([])
    useEffect(() => {
        getLogs();
    }, [])
    async function getLogs() {
        try {
            let apiEndpoint = NodebaseUrlDefiner();
            const { data } = await axios({
                url: `${apiEndpoint}/adminCredit/getUserDetails`,
                method: "GET"
            })
            setuserList(data?.status === 200 ? data.data : []);
        } catch (err) {
            console.log(err)
            toast.err("Error fetching sellers!")
        }
    }
    // console.log("userDetails29999", userDetails);
    const COLUMNS = [
        {
            Header: "Sn No.",
            accessor: (row, count) => {
                return <>{count + 1}</>
            },
            disableFilters: true,
            sticky: "left"
        },
        {
            Header: "Name",
            accessor: (userList) => (userList?.firstName),
            sticky: "left"
        },
        {
            Header: "error",
            accessor: (item) => (item?.email),
            sticky: "left",
        },
        {
            Header: "Official Email",
            accessor: (userList) => (userList?.officialEmail),
            sticky: "left",
        },
        {
            Header: "DOB",
            accessor: (userList) => (userList?.dob),
            sticky: "left",
        },
        {
            Header: "Form Id",
            accessor: (userList) => (userList?.formId),
            sticky: "left",
        },
        {
            Header: "Submission Id",
            accessor: (userList) => (userList?.submissionId),
            sticky: "left",
        },
        {
            Header: "Details",
            accessor: (userList) => {
                return <div className="d-flex"><Link to={`./orderdetails/${userList.formId}`} className="btn btn-success btn-circle"><i className="fas fa-eye"></i></Link></div>
            },
            sticky: "left"
        }
    ];
    return (
        <ThemeMaker>
            <div className="row w-100 m-0 mt-1">
                <div className="col-md-12 ">
                    <div className="card shadow mb-4">
                        <div className="card-header p-2">
                            <div className={`d-flex justify-content-between p-2 align-items-center}`}>
                                <h6 className="m-0 font-weight-bold text-primary text-left" style={{ width: "100px" }}>Orders In process</h6>
                                <div className="d-flex align-items-center w-100">
                                    <div className="d-flex flex-column flex-md-row justify-content-between align-items-center mt-4 ml-2 mt-sm-0 w-100" style={{ gap: "11px" }}>
                                        <input type="text" className="form-control  w-100 w-md-50 mt-2 mt-md-0" placeholder="Enter Transaction ID.." />
                                    </div>
                                    <div className="d-flex  align-items-center" style={{ gap: "11px" }}>
                                        <button className="btn btn-primary commonBtn ">Filters</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-header py-3">
                            <h6 className="m-0 font-weight-bold text-primary text-left">Finace Order Lists</h6>
                        </div>
                        <div className="card-body p-0 p-1" style={{ minHeight: "70vh", overflowX: "auto" }}>
                            <Paginated data={userList} columns={COLUMNS} />
                        </div>
                    </div>
                </div>
            </div>
        </ThemeMaker>
    )
}
